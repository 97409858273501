import React, { useEffect } from 'react';
import style from './Talents.module.scss';
import Navigation from '../../components/Navigation/Navigation';
import dataTalents from '../../assets/data/talents.json';
import { useState, useContext    } from 'react';
import cartIcon from '../../assets/img/shopping-bag.svg';
import arrowIcon from '../../assets/img/arrow-down.svg';
import AuthContext from "../../utils/DataProvider.jsx";
import Product from '../../components/Product/Product.jsx';
import TextTransition, { presets } from 'react-text-transition';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';

const Talents = () => {
    const [talents, setTalents] =  useState([]) ;
    const [products, setProducts] =  useState([]);
    const [activeTalent, setActiveTalent] =  useState(null);
    const {openCartView, setOpenCartView,activeGlobalTalent, setActiveGlobalTalent} =  useContext(AuthContext);
    const { state } = useLocation();
    
    
    useEffect(() => {
        setTalents(dataTalents?.talents);
        setProducts(dataTalents?.products);
        window.scrollTo({ top: 0, left: 0 });

    
        
    }, []);

    useEffect(() => {
        if(activeGlobalTalent && talents.length > 0){
            setActiveTalent(talents.find(talent => talent.id === activeGlobalTalent));
            setActiveGlobalTalent(null);
        }
    } , [talents]);
    
    const handleMouseEnter = (talent) => {
      
    }
    const handleClick = (talent) => {
        
        setActiveTalent(talent);
    }
    //https://codesandbox.io/p/sandbox/react-3d-rotation-box-mhj36?file=%2Findex.js
    return (
        <div>
            <Navigation/>
            <Header/>
            <div>
                .
            </div>

            
                
            <h1 className={style.talents__wip}>Stiamo lavorando a questa pagina...</h1>  
            {/* 
            <div className={style.talents__cartIcon} onClick={() => setOpenCartView(true)}>
                <img src={cartIcon} alt="open cart" />
            </div>
            
            <div className={style.talents__list}>   
                {talents && talents.map((talent, key) => {
                    return(
                        <a key={key} onMouseEnter={() => handleMouseEnter(talent)} onClick={() => handleClick(talent)}
                        className={(talent == activeTalent) ?  style.talents__list__active : style.talents__list__item }>
                            {talent.name}
                        </a>
                    )

                })}
            </div>

            {(products && activeTalent) && 
                <>
                    {/* {activeTalent.name} //talent name *}
                    <img  className={style.talents__arrowIcon} src={arrowIcon} alt="arrow scroll down" />
                </>
            }

            
            { activeTalent &&
                 <TextTransition translateValue={"9%"} springConfig={presets.gentle} className={style.talents__description} >{activeTalent.description}</TextTransition>
            }
            <div className={style.shop__list}>  
               

                {(products && activeTalent) && products.filter(product => product.talent === activeTalent?.id).map((product, key) => {
                    return (<Product key = {key} product={product}/>)
                })}
            </div> */}
           
           
            <Footer/>
            
            
        </div>
    );
};




export default Talents;