import React from 'react';
import style from './Journal.module.scss';
import Navigation from '../../components/Navigation/Navigation';
import dataJournal from '../../assets/data/journal.json';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Journal = () => {
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    setArticles(dataJournal.articles);
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div >
      <div className={style.journal}>
        <div className={style.journal__bar}></div>

        <h1>Journal</h1>
        <div className={style.journal__nav}>
          <NavLink to="/journal/heritage" className={style.journal__nav__item}>
            <h2>HERITAGE</h2>
          </NavLink>
          <NavLink to="/journal/sustainability" className={style.journal__nav__item}>
            <h2>SUSTAINABILITY</h2>
          </NavLink>
        </div>

      </div>
      <Navigation />
    </div>
  );
};


export default Journal;