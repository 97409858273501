import React from 'react';
import style from './Shop.module.scss';
import Navigation from '../../components/Navigation/Navigation';
import { useState, useEffect, useContext } from 'react';
import dataTalents from '../../assets/data/talents.json';
import cartIcon from '../../assets/img/shopping-bag.svg';



import AuthContext from "../../utils/DataProvider.jsx";
import Product from '../../components/Product/Product.jsx';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';


const Shop = () => {
    const [products, setProducts] = useState(null);
    const { openCartView, setOpenCartView, getCart, cart } = useContext(AuthContext);

    useEffect(() => {
        setProducts(dataTalents?.products);
        getCart();
        window.scrollTo({ top: 0, left: 0 });
    }, []);


    return (
        <div className={style.shop}>
            <Header />
            <Navigation />
            <div className={style.shop__list}>
                <h1 className={style.shop__wip}>Stiamo lavorando a questa pagina...</h1>
            </div>
            {/* <div className={style.shop__cartIcon} onClick={() => setOpenCartView(true)}>
                <img src={cartIcon} alt="open cart" loading="lazy" />
            </div>
            <div className={style.shop__list}>
                {products && products.map((product, key) => {
                    return (<Product key={key} product={product} />)
                })}
            </div> */}

            <Footer/>

        </div>
    );
};

export default Shop;